
:root {
    /* Base colors */
    --odj-white: #E6E6E6;
    --odj-white-rgb: 230,230,230;

    --odj-white-dark: #666;
    --odj-white-mid: #A6A6A6;

    --odj-black-light: #404040;
    --odj-black-mid: #303030;

    --odj-green-dark: #507A53;
    --odj-green-light: #66A06A;

    --odj-red-dark: #BB4B45;
    --odj-red-light: #C4635E;

    --odj-blue-dark: #1E7091;
    --odj-blue-mid: #299CC9;

    --odj-purple: #842A98;
    --odj-purple-light: #BB57D1;
    --odj-purple-dark: #5C1D6A;

    --odj-yellow-dark: #C28331;



    --odj-background-color: #121212;
    --odj-font-family: 'Open Sans', system-ui, Arial, sans-serif;
    
    --odj-icon-color: #5A6268;
    

    /* Inputs */
    --odj-input-background: #242424;
    --odj-input-background-hover: #303030;
    --odj-input-color: #E6E6E6;
    --odj-input-icon-color: var(--odj-white-dark);
    --odj-input-active-icon-color: var(--odj-white-mid);
    --odj-input-placeholder-color: var(--odj-white-dark);
    
    
    --odj-input-border-color: #404040;
    --odj-input-active-border-color: var(--odj-white-mid);
    --odj-input-valid-border-color: var(--odj-green-dark);
    --odj-input-active-valid-border-color: var(--odj-green-light);
    --odj-input-invalid-border-color: var(--odj-red-dark);
    --odj-input-active-invalid-border-color: var(--odj-red-light);

    --odj-input-validating-color: var(--odj-blue-mid);

    /* Alignment, now obsolete because we got rid of the status indicators */
    --odj-form-inset: 100%;
    --odj-status-indicator-width: 0;


    --odj-error-color: var(--odj-red-light);
    --odj-success-color: var(--odj-green-light);
    --odj-help-text-color: var(--odj-white-mid);
    
    --odj-tooltip-background: #32383e;
    --odj-tooltip-text-color: #ddd;
    --odj-tooltip-border-radius: 5px;
    --odj-tooltip-padding-vertical: 9px;
    --odj-tooltip-padding-horizontal: 14px;
    --odj-tooltip-font-size: 0.825rem;

    --odj-toast-background-color: #242424;

    --odj-form-opacity-disabled: 0.6;


    /* Badges are non-clickable labels */
    --odj-badge-text-color: var(--odj-white);
    --odj-badge-success: var(--odj-green-dark);
    --odj-badge-warning: var(--odj-yellow-dark);
    --odj-badge-danger: var(--odj-red-dark);
    --odj-badge-active: var(--odj-purple);
    --odj-badge-inactive: var(--odj-black-light);
    --odj-badge-default: var(--odj-black-light);
    --odj-badge-info: var(--odj-blue-dark);
    --odj-badge-open-beta: #a4c43f;
    --odj-badge-closed-beta: #3baea6;


    /* Labels are clickable and used for toggleable chart legends and the tage input */
    --odj-label-background: var(--odj-black-mid);
    --odj-label-background-hover: var(--odj-black-light);
}

*:not(:defined) {
    display: inline-block;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.odj-scroll-lock {
    overflow: hidden;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@import 'styleguide/table.scss'