.odj-table {
    width: 100%;
    border-spacing: 0;

    thead {
        text-align: left;
        font-size: 0.875em;
        
        tr {
            color: #E6E6E6;
            background: #242424;
            font-weight: bold;
        }
    }

    tbody {
        tr {
            color: #A6A6A6;

            &[data-href] {
                cursor: pointer;
            }

            &:nth-child(odd) {
                background: #121212;
            }


            &:nth-child(even) {
                background: #242424;
            }

            &:hover {
                background: #303030;
                color: #fff;
            }
        }

    }

    td, th {
        padding: 0.5rem;
    }

}